import React from "react";
import SingleSkill from "./SingleSkill";

const Skills = () => {
  const frontend = [
    {
      name: "HTML 5",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/html-5-svgrepo-com.b58b9d2b.svg",
      level: "Expert",
      color: "#43A047",
    },
    {
      name: "CSS 3",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/css-3-svgrepo-com.934fe664.svg",
      level: "Expert",
      color: "#43A047",
    },
    {
      name: "Bootstrap",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/bootstrap-svgrepo-com.070f18f9.svg",
      level: "Expert",
      color: "#43A047",
    },
    {
      name: "Tailwind CSS",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/tailwind-svgrepo-com.8bce21fd.svg",
      level: "Expert",
      color: "#43A047",
    },
    {
      name: "JavaScript",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/javascript-svgrepo-com.b537477c.svg",
      level: "Expert",
      color: "#43A047",
    },
    {
      name: "TypeScript",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/typescript-svgrepo-com.8cc93562.svg",
      level: "Intermediate",
      color: "#00ACC1",
    },
    {
      name: "React JS",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/react-svgrepo-com.552e2d5d.svg",
      level: "Expert",
      color: "#43A047",
    },
    {
      name: "React Router",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/react-router-svgrepo-com.15f2c37b.svg",
      level: "Expert",
      color: "#43A047",
    },
    {
      name: "NextJS",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/next-js-svgrepo-com.d5b8baed.svg",
      level: "Intermediate",
      color: "#00ACC1",
    },
    {
      name: "Firebase",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/firebase-svgrepo-com.50f1b1c9.svg",
      level: "Expert",
      color: "#43A047",
    },
    {
      name: "Redux",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/redux-svgrepo-com.d8a668f6.svg",
      level: "Basic",
      color: "#E57373",
    },
    {
      name: "Wordpress",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/wordpress-color-svgrepo-com.bf273237.svg",
      level: "Intermediate",
      color: "#00ACC1",
    },
  ];

  const backend = [
    {
      name: "Node JS",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/node-js-svgrepo-com.726f6c57.svg",
      level: "Intermediate",
      color: "#00ACC1",
    },
    {
      name: "Express JS",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/icons8-express-js.d418bd7e.svg",
      level: "Expert",
      color: "#43A047",
    },
    {
      name: "MongoDB",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/mongodb-svgrepo-com.82bd8e65.svg",
      level: "Expert",
      color: "#43A047",
    },
    {
      name: "JWT",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/icons8-jwt.47a54c01.svg",
      level: "Expert",
      color: "#43A047",
    },
  ];

  const tools = [
    {
      name: "VS Code",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/vscode-svgrepo-com.f018dc3d.svg",
      level: "Expert",
      color: "#43A047",
    },
    {
      name: "Github",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/github-142-svgrepo-com.e449b72c.svg",
      level: "Expert",
      color: "#43A047",
    },
    {
      name: "Figma",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/figma-svgrepo-com.82f9a4f3.svg",
      level: "Expert",
      color: "#43A047",
    },
    {
      name: "Postman",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/postman-icon-svgrepo-com.43965f47.svg",
      level: "Expert",
      color: "#43A047",
    },
    {
      name: "Trello",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/trello-svgrepo-com.2350af7e.svg",
      level: "Intermediate",
      color: "#00ACC1",
    },
    {
      name: "Netlify",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/netlify-svgrepo-com.b481c857.svg",
      level: "Expert",
      color: "#43A047",
    },
    {
      name: "Vercel",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/vercel-fill-svgrepo-com.201e55c5.svg",
      level: "Expert",
      color: "#43A047",
    },
    {
      name: "Heroku",
      img: "https://griding-styles-porfolio.vercel.app/_next/static/media/heroku-svgrepo-com.659ce97c.svg",
      level: "Expert",
      color: "#43A047",
    },
  ];
  return (
    <div className="text-white mt-12 overflow-hidden">
        <div className="flex justify-center">
            <img src="star1.webp" alt="" />
            <p className="text-4xl md:text-5xl font-bold uppercase text-center">Skills I Have</p>
            <img src="star1.webp" alt="" />
        </div>
        <div>
            <p className="text-3xl xl:text-4xl lg:ml-20 flex justify-center font-bold mb-4 mt-12">Frontend Technologies</p>
            <div className="grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 xl:grid-cols-4  w-full md:w-full lg:w-[1300px] place-items-center mx-auto">
                {
                    frontend?.map(skill => <SingleSkill skill={skill}></SingleSkill>)
                }
            </div>
        </div>
        <div>
            <p className="text-3xl xl:text-4xl  lg:ml-20 text-center font-bold mb-4 mt-12">Backend Technologies</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 w-full md:w-full lg:w-[1300px] place-items-center mx-auto">
                {
                    backend?.map(skill => <SingleSkill skill={skill}></SingleSkill>)
                }
            </div>
        </div>
        <div>
            <p className="text-3xl xl:text-4xl  lg:ml-20 text-center font-bold mb-4 mt-12">Tools I Used</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 w-full md:w-full lg:w-[1300px] place-items-center place-content-center justify-center mx-auto">
                {
                    tools?.map(skill => <SingleSkill skill={skill}></SingleSkill>)
                }
            </div>
        </div>
    </div>
  )
};

export default Skills;
