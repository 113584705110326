import React from "react";
import LeftProjects from "./LeftProjects";
import CenterProjects from "./CenterProjects";
import BottomProjects from "./BottomProject";

const Project = () => {
  const leftProjects = [
    {
      id: 1,
      description: "Web3 Teaching Evaluation System",
      name: "Web3 University Management System",
      img: "https://i.postimg.cc/7ZyD18hW/9.png",
    },
    {
      id: 2,
      description: "Manufacturing Management System",
      name: "SpeedX Manufacturer",
      img: "https://i.postimg.cc/FH8kZ6yk/4.png",
    },
  ];

  const centerProjects = [
    {
      id: 3,
      description: "Hotel Management System",
      name: "Hotel Management System",
      img: "https://i.postimg.cc/Bvh16fGt/6.png",
    },
    {
      id: 4,
      description: "Warehouse Management System",
      name: "RedX Warehouse",
      img: "https://i.postimg.cc/cLsfXcxM/1.png",
    },
    {
      id: 5,
      description: "Dentist Website",
      name: "My Dentist",
      img: "https://i.postimg.cc/6qF04xVW/1.png",
    },
    {
      id: 6,
      description: "BD Result Viewing System",
      name: "Result BD",
      img: "https://i.postimg.cc/857HwCzd/2.png",
    },
  ];

  const buttonProjects = [
    {
      id: 7,
      description: "Business Agency Website",
      name: "Business Agency",
      img: "https://i.postimg.cc/vZMwKJ9x/2.png",
    },
    {
      id: 8,
      description: "Embetter Agency Website",
      name: "Embetter Agency",
      img: "https://i.postimg.cc/ncmngKPg/3.png",
    },
  ];
  return (
    <div className="overflow-hidden">
      <div className="text-white xl:flex container mx-auto justify-center gap-4 mt-8">
        <div>
          {leftProjects?.map((project) => (
            <LeftProjects project={project}></LeftProjects>
          ))}
        </div>
        <div>
          <div className="xl:flex items-center justify-center mb-4 hidden xl:block">
            <img src="star1.webp" alt="" />
            <p className="text-5xl font-bold uppercase">All Projects</p>
            <img src="star1.webp" alt="" />
          </div>
          <div className="grid xl:grid-cols-2 gap-4">
            {centerProjects?.map((project) => (
              <CenterProjects project={project}></CenterProjects>
            ))}
          </div>
        </div>
      </div>

      <div className="container mx-auto flex justify-center">
        <div className="grid xl:grid-cols-2 gap-4">
          {buttonProjects?.map((project) => (
            <BottomProjects project={project} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Project;
