import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

const LeftProjects = ({ project }) => {
  const { id, description, name, img } = project;
  return (
    <Link to={`/projects/${id}`} className="flex justify-center xl:flex-none">
      <div className="relative group mb-4 pt-6 w-[430px] h-[375px] md:w-[600px] md:h-[375px] xl:w-[430px] xl:h-[375px] text-white pb-8 pl-4 pr-4 bg-gradient-to-r from-[#1F1F1F] to-[#141414] shadow-black gap-4 bg-clip-border rounded-3xl active:scale-90 duration-300">
        <div>
          <img
            className="w-[384px] h-[256px] md:w-[514px] mx-auto rounded-2xl"
            src={img}
            alt=""
          />
        </div>
        <div className="flex px-2">
          <div>
            <p className="uppercase mt-6 text-[#8B8B8B] text-[15px] lg:text-md font-bold">
              {description}
            </p>
            <p className=" text-2xl font-bold">{name}</p>
          </div>
          <FaArrowRightLong className="text-[#989898] group-hover:text-white duration-300 absolute bottom-6 right-8" />
        </div>
      </div>
    </Link>
  );
};

export default LeftProjects;
