import React from "react";

const Services = () => {
  return (
    <div className="text-white lg:flex justify-center items-center mt-8 gap-8">
      <div className="w-11/12 h-[610px] mx-auto lg:mx-0 mb-4 lg:w-[420px] lg:h-[600px] rounded-2xl p-8 bg-[#1A1A1A] shadow-black shadow-xl">
        <div className=" flex items-center text-lg lg:text-xl mb-12 gap-4">
          <img src="data-1.webp" alt="" />
          <p className="uppercase">Web Development</p>
        </div>
        <div className=" flex items-center text-lg lg:text-xl mb-12 gap-4">
          <img src="data2.webp" alt="" />
          <p className="uppercase">Modern Website</p>
        </div>
        <div className=" flex items-center text-lg lg:text-xl mb-12 gap-4">
          <img src="data3.webp" alt="" />
          <p className="uppercase">Frontend Development</p>
        </div>
        <div className=" flex items-center text-lg lg:text-xl mb-12 gap-4">
          <img src="data4.webp" alt="" />
          <p className="uppercase">Full Stack Development</p>
        </div>
        <div className=" flex items-center text-lg lg:text-xl mb-12 gap-4">
          <img src="data5.webp" alt="" />
          <p className="uppercase">Website Maintenance</p>
        </div>
        <div className=" flex items-center text-lg lg:text-xl mb-12 gap-4">
          <img src="data6.webp" alt="" />
          <p className="uppercase">Wordpress Development</p>
        </div>
      </div>

      <div>
        <div className="flex items-center justify-center mb-3 lg:mb-0 mt-8 lg:mt-0">
          <img src="star1.webp" alt="" />
          <p className="uppercase text-2xl lg:text-4xl font-bold">Services Offering</p>
          <img src="star1.webp" alt="" />
        </div>
        <div className="w-11/12  xl:w-[855px] xl:h-[567px] grid grid-cols-1 xl:grid-cols-2 mx-auto gap-3 bg-[#202020] shadow-xl rounded-2xl justify-center p-3 shadow-black">
          <div className="w-11/12 mx-auto lg:w-[400px] h-[165px] mb-3 lg:mb-0 lg:h-[150px] rounded-3xl bg-[#1A1A1A] shadow-black shadow-xl px-4">
            <p className="text-xl lg:text-2xl font-bold mt-4">Web Development</p>
            <p className="mt-3">
              I specialize in crafting visually appealing and functional
              websites that make a lasting impression.
            </p>
          </div>
          <div className="w-11/12 mx-auto lg:w-[400px] h-[165px] mb-3 lg:mb-0 lg:h-[150px] rounded-3xl bg-[#1A1A1A] shadow-black shadow-xl px-4">
            <p className="text-xl lg:text-2xl font-bold mt-4">Modern Website</p>
            <p className="mt-3">
              Need a powerful and dynamic web application? I've got you covered
              I can make modern web app by using React JS or Next JS.
            </p>
          </div>
          <div className="w-11/12 mx-auto lg:w-[400px] h-[165px] mb-3 lg:mb-0 lg:h-[150px] rounded-3xl bg-[#1A1A1A] shadow-black shadow-xl px-4">
            <p className="text-xl lg:text-2xl font-bold mt-4">Frontend Development</p>
            <p className="mt-3">
              I excel in frontend development, crafting intuitive and engaging
              user interfaces. With expertise in HTML and CSS.
            </p>
          </div>
          <div className="w-11/12 mx-auto lg:w-[400px] h-[165px] mb-3 lg:mb-0 lg:h-[150px] rounded-3xl bg-[#1A1A1A] shadow-black shadow-xl px-4">
            <p className="text-xl lg:text-2xl font-bold mt-4">Full Stack Website (MERN)</p>
            <p className="mt-3">
              I specialize in crafting visually appealing and functional
              websites that make a lasting impression.
            </p>
          </div>
          <div className="w-11/12 mx-auto lg:w-[400px] h-[165px] mb-3 lg:mb-0 lg:h-[150px] rounded-3xl bg-[#1A1A1A] shadow-black shadow-xl px-4">
            <p className="text-xl lg:text-2xl font-bold mt-4">Website Maintenance</p>
            <p className="mt-3">
              Already have a website or web application but need ongoing
              maintenance? I provide reliable and efficient maintenance
              services.
            </p>
          </div>
          <div className="w-11/12 mx-auto lg:w-[400px] h-[165px] mb-3 lg:mb-0 lg:h-[150px] rounded-3xl bg-[#1A1A1A] shadow-black shadow-xl px-4">
            <p className="text-xl lg:text-2xl font-bold mt-4">Wordpress Development</p>
            <p className="mt-3">
              I am so confident about the wordpress skill cause I am working
              with it from 3 years. I will give you a creative website by that
              tech.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
