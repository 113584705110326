import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-[#0F0F0F] pt-20 text-white pb-12">
      <img className="mx-auto mb-4" src="https://i.postimg.cc/pdX2ym2B/lg.png" alt="" />
      <div className="flex justify-center">
        <ul>
          <li className="flex flex-col md:flex-row gap-6 md:gap-8">
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/skills">Skills</Link>
            <Link to="/projects">Projects</Link>
            <Link to="/blogs">Blogs</Link>
            <Link to="/services">Services</Link>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
      <p className="text-center mt-4">
        © All rights reserved by{" "}
        <Link to="https://uttamsaha.com" target="_blank">
          <span className="text-[#00FFFF] cursor-pointer">
            Uttam Kumar Saha
          </span>
        </Link>
      </p>
    </div>
  );
};

export default Footer;
