import React from 'react'

const Blogs = () => {
  return (
    <div>
        <p className='text-3xl lg:text-6xl font-bold text-white text-center mt-20'>Blogs Coming Soon...</p>
    </div>
  )
}

export default Blogs