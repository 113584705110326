import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import  { useState } from 'react';
import { BookOpenIcon, Bars3BottomRightIcon, XMarkIcon } from '@heroicons/react/24/solid'

const Header = () => {
    let [open, setOpen] =useState(false);
    let Links =[
        {name:"Hoe",link:"/"},
        {name:"SERVICE",link:"/"},
        {name:"ABOUT",link:"/"},
        {name:"CONTACT",link:"/"},
      ];
    const navigate = useNavigate();
    const letsTalk = ()=> {
        navigate("/contact");
    }
  return (
    <div className=' h-[80px] bg-transparent flex justify-center'>
        <nav className=' h-[80px] bg-[#0F0F0F]  flex justify-normal xl:justify-around items-center container  backdrop-saturate-200 backdrop-blur-2xl bg-opacity-80 border border-white/80 text-white mx-auto xl:rounded-2xl lg:px-28 rounded-none z-10 fixed top-0 shadow-none py-2 bg-transparent border-none lg:py-4'>
            <div className='ml-4 xl:ml-0'>
                <img className='h-12' src="https://i.postimg.cc/pdX2ym2B/lg.png" alt="" />
            </div>
            <div onClick={()=>setOpen(!open)} className='absolute right-8 top-6 cursor-pointer md:hidden w-7 h-7'>
                {
                    open ? <XMarkIcon/> : <Bars3BottomRightIcon />
                }
            </div>
        
            <ul className={`bg-gradient-to-r flex flex-col from-[#1F1F1F] to-[#141414] xl:bg-none ml-4 xl:ml-0 mt-6 xl:mt-0 pt-4 xl:pt-0 rounded-xl  md:flex md:items-center md:pb-0 pb-12 absolute md:static  md:z-auto z-[-1] left-0 w-11/12  md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-12' : 'top-[-880px]'}`}>
                <li className='flex flex-col xl:flex-row text-white gap-4 lg:gap-8 text-center xl:text-left'>
                    <Link className='text-[20px] xl:text-[18px]' to="/">Home</Link>
                    <Link className='text-[20px] xl:text-[18px]' to="/about">About</Link>
                    <Link className='text-[20px] xl:text-[18px]' to="/skills">Skills</Link>
                    <Link className='text-[20px] xl:text-[18px]' to="/projects">Projects</Link>
                    <Link className='text-[20px] xl:text-[18px]' to="/blogs">Blogs</Link>
                    <Link className='text-[20px] xl:text-[18px]' to="/services">Services</Link>
                    <Link className='text-[20px] xl:text-[18px]' to="/contact">Contact</Link>
                </li>
                <div className='flex flex-col items-center gap-3 mt-3 lg:hidden'>
                    <button onClick={letsTalk} className='w-4/5 uppercase bg-[#424242] rounded-full py-3 px-6 text-sm hover:hover:bg-stone-200 hover:text-black active:scale-90 duration-500'>Lets Talk</button>
                    <button className=' w-4/5 uppercase  bg-[#424242] rounded-full py-3 px-6 text-sm hover: hover:bg-stone-200 hover:text-black active:scale-90 duration-500'>Get my resume</button>
                </div>
                
            </ul>

            <div className='flex items-center gap-2 hidden xl:block'>
                <button onClick={letsTalk} className='uppercase bg-[#424242] rounded-full py-3 px-6 text-sm hover:hover:bg-stone-200 hover:text-black active:scale-90 duration-500 xl:mr-3'>Lets Talk</button>
                <button className='uppercase  bg-[#424242] rounded-full py-3 px-6 text-sm hover: hover:bg-stone-200 hover:text-black active:scale-90 duration-500'>Get my resume</button>
            </div>
            
            
        </nav>
    </div>
  )
}

export default Header;