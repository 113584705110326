import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Footer from './Pages/Footer/Footer';
import Services from './Pages/Services/Services';
import Contact from './Pages/Contact/Contact';
import Skills from './Pages/Skills/Skills';
import Blogs from './Pages/Blogs/Blogs';
import { useEffect } from 'react';
import About from './Pages/about/about';
import Project from './Pages/Project/Project';
import swal from 'sweetalert';
import ViewProject from './Pages/Project/ViewProject';
import NotFound from './Pages/NotFound/NotFound';
import Navbar from './Pages/NotFound/Navbar';
import Header from './Pages/Header/Header';

function App() {

  const addTwo = (event) => {
    // console.log("hello")
    window.addEventListener('keydown', function (event) {
      if (event.keyCode == 123) {
        // swal({
        //   title: "Not allowed",
        //   text: "You clicked F12",
        //   icon: "error",
        //   button: "Ok",
        // });
          event.preventDefault();
      } else if (event.ctrlKey && event.shiftKey && event.keyCode == 73) {
          // swal({
          //   title: "Not allowed",
          //   text: "You clicked CTRL+SHIFT+I",
          //   icon: "error",
          //   button: "Ok",
          // });
          event.preventDefault(); // Prevent from ctrl+shift+i
      } else if (event.ctrlKey && event.keyCode == 73) {
        // swal({
        //   title: "Not allowed",
        //   text: "You clicked CTRL+SHIFT+I",
        //   icon: "error",
        //   button: "Ok",
        // });
          event.preventDefault(); // Prevent from ctrl+shift+i
      }
      else if(event.ctrlKey && event.keyCode == 74){
        // swal({
        //   title: "Not allowed",
        //   text: "You clicked CTRL+SHIFT+J",
        //   icon: "error",
        //   button: "Ok",
        // });
        event.preventDefault();
        return;
      }
      else if(event.ctrlKey && event.keyCode == 67){
        // swal({
        //   title: "Not allowed to copy anything",
        //   text: "Not allowed",
        //   icon: "error",
        //   button: "Ok",
        // });
        event.preventDefault();
        return;
      }
  });
  
  document.addEventListener("contextmenu", function (e) {
    // swal({
    //   title: "Right Click Not allowed",
    //   text: "Right click is disabled",
    //   icon: "error",
    //   button: "Ok",
    // });
      e.preventDefault();
  });
  
  }
  useEffect(() => {
    addTwo();
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }


    
}, [ ])
  return (
    <div>
      <Header></Header>
      {/* <Navbar></Navbar> */}
      <Routes>
        <Route path='/' element={<Home></Home>}></Route>
        <Route path='/services' element={<Services></Services>}></Route>
        <Route path="/projects" element={<Project></Project>}></Route>
        <Route path='/projects/:projectId' element={<ViewProject></ViewProject>}></Route>
        <Route path='/contact' element={<Contact></Contact>}></Route>
        <Route path='/skills' element={<Skills></Skills>}></Route>
        <Route path='/blogs' element={<Blogs></Blogs>}></Route>
        <Route path='/about' element={<About></About>}></Route>
        <Route path='*' element={<NotFound></NotFound>}></Route>
        
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
