import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { PiStudentBold } from "react-icons/pi";
import { Link } from "react-router-dom";


const About = () => {
  const handleClick = ()=> {
    window.open("https://www.facebook.com/uttamsaha404/", "_blank")
  }
  return (
    <div className="text-white xl:flex justify-center mt-4 xl:mt-12 gap-8 ">
     <div className="flex justify-center xl:flex-none xl:justify-normal mb-8 xl:mb-0">
     <div className="w-[390px] h-[610px] bg-gradient-to-r from-[#1F1F1F] to-[#141414] rounded-xl container sticky top-32 shadow-2xl shadow-black">
        <div className="p-6">
          <img className="rounded-xl" src="uttam.jpg" alt="" />
        </div>
        <div>
          <p className="text-2xl uppercase text-center font-extrabold">
            Uttam Kumar Saha
          </p>
          <p className="text-lg text-center">@uttamsaha</p>
        </div>
        <div className="mt-4">
          <div className="flex gap-4 justify-center w-1/2 mx-auto">
            <Link to="https://www.linkedin.com/in/uttamsaha404/" target="_blank"><img className="w-12" src="s1.webp" alt="" /></Link>
            <Link to="https://github.com/uttamsaha" target="_blank"><img className="w-12" src="s2.webp" alt="" /></Link>
            <Link to="https://join.skype.com/invite/UlqUgBACVMqb" target="_blank"><img className="w-12" src="s3.webp" alt="" /></Link>
            <Link to="https://facebook.com/uttamsaha404" target="_blank"><img className="w-12" src="s4.webp" alt="" /></Link>
          </div>
        </div>
        <div className="flex justify-center">
          <button onClick={handleClick} to="https://www.facebook.com/uttamsaha404/" target="_blank" className="uppercase text-md bg-[#424242] w-[350px] h-[40px] rounded-xl mt-4 hover:bg-stone-200 hover:text-black">
            Contact Me
          </button>
        </div>
      </div>
     </div>

      <div>
        <div className="w-11/12 xl:w-[860px] mx-auto xl:h-[255px] bg-gradient-to-r from-[#1F1F1F] to-[#141414] shadow-xl rounded-2xl p-4 shadow-black">
          <div className="relative">
            <div className="flex absolute right-2 top-4 gap-2">
              <div className="w-3 h-3 rounded-full bg-red-500 hover:brightness-75"></div>
              <div className="w-3 h-3 rounded-full bg-yellow-500 hover:brightness-75"></div>
              <div className="w-3 h-3 rounded-full bg-green-500 hover:brightness-75"></div>
            </div>
            <p className="uppercase mt-4 text-2xl lg:text-3xl font-bold">About me</p>
            <p className="mb-4 mt-4">
              I am a Full Stack Developer (MERN Stack) who is based on
              Bangladesh. I have 3 years of experience with the Frontend
              Development and 2 years of experience with the full stack
              technologies. I am avilaible to work full-time remotely and
              on-site in Bangladesh. I can make the modern websites or the
              single page web apps.
            </p>
            <p>
              I am a passionate developer. I specialize in front-end
              development, crafting visually appealing and user-friendly
              websites. Transforming ideas into exceptional digital experiences
              - that's what drives me as a developer. I am a passionate
              developer, so I enjoy working with new technologies and love to
              explore a lot.
            </p>
          </div>
        </div>

        <div className="w-11/12 xl:w-full mx-auto h-[670px] pl-6 relative shadow-xl shadow-black">
        <div className="flex absolute right-2 top-4 gap-2">
              <div className="w-3 h-3 rounded-full bg-red-500 hover:brightness-75"></div>
              <div className="w-3 h-3 rounded-full bg-yellow-500 hover:brightness-75"></div>
              <div className="w-3 h-3 rounded-full bg-green-500 hover:brightness-75"></div>
            </div>
          <div>
            <p className="text-2xl lg:text-3xl font-bold  uppercase  mb-4 mt-12">Education</p>
          </div>

          <div className="border-l-2 h-[560px] xl:h-[500px]">
            <div className="group">
              <div className="flex items-center gap-4 font-bold">
                <PiStudentBold className="text-3xl bg-white text-black rounded-full -ml-4 group-hover:bg-black group-hover:text-white  duration-500" />
                <p className="font-black text-[#989898] group-hover:text-white duration-500">2019-2023 (4years)</p>
              </div>
              <div className="ml-8 mt-4">
                <p className=" text-xl lg:text-2xl font-bold">
                  B.Sc in Computer Science and Engineering
                </p>
                <p className="text-lg">Southeast University, Dhaka</p>
                <p>CGPA: 3.88</p>
              </div>
            </div>

            <div className="mt-8 group">
              <div className="flex items-center gap-4 font-bold">
                <PiStudentBold className="text-3xl bg-white text-black rounded-full -ml-4 group-hover:bg-black group-hover:text-white  duration-500" />
                <p className="font-black text-[#989898] group-hover:text-white duration-500">2016-2018 (2 years)</p>
              </div>
              <div className="ml-8 mt-4">
                <p className=" text-xl lg:text-2xl font-bold">
                Higher Secondary School Certificate
                </p>
                <p className="text-lg">Govt Shahid Bulbul College, Pabna</p>
                <p className="">GPA: 4</p>
              </div>
            </div>

            <div className="mt-8 group">
              <div className="flex items-center gap-4 font-bold">
                <PiStudentBold className="text-3xl bg-white text-black rounded-full -ml-4 group-hover:bg-black group-hover:text-white  duration-500" />
                <p className="font-black text-[#989898] group-hover:text-white duration-500">2014-2016 (2 years)</p>
              </div>
              <div className="ml-8 mt-4">
                <p className=" text-xl lg:text-2xl font-bold">
                Secondary School Certificate.
                </p>
                <p className="text-lg">Selim Nazir High School, Pabna</p>
                <p className="">GPA: 4.50</p>
              </div>
            </div>
            <div className="mt-8 group">
              <div className="flex items-center gap-4 font-bold">
                <PiStudentBold className="text-3xl bg-white text-black rounded-full -ml-4 group-hover:bg-black group-hover:text-white  duration-500" />
                <p className="font-black text-[#989898] group-hover:text-white duration-500">2012-2013 (1 year)</p>
              </div>
              <div className="ml-8 mt-4">
                <p className=" text-xl lg:text-2xl font-bold">
                Junior School Certificate
                </p>
                <p className="text-lg">Ataikula High School, Pabna</p>
                <p className="">GPA: 4.69</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-8">
          <div>
            <p className="xl:mt-12 text-2xl lg:text-3xl ml-4 font-bold uppercase mb-4 mt-36">
              Experience Summary
            </p>
          </div>
          <div className="flex flex-col xl:flex-row items-center justify-center gap-4">
            <div className="w-[275px] h-[170px] bg-gradient-to-r from-[#1F1F1F] to-[#141414]  rounded-xl p-4 flex flex-col items-center justify-center shadow-xl shadow-black">
              <p className="text-4xl font-bold ">03</p>
              <p className="text-[#616161] text-lg font-bold uppercase">
                Years Experience
              </p>
            </div>
            <div className="w-[275px] h-[170px] bg-gradient-to-r from-[#1F1F1F] to-[#141414] rounded-xl p-4 flex flex-col items-center justify-center shadow-xl shadow-black">
              <p className="text-4xl font-bold ">+20</p>
              <p className="text-[#616161] text-lg font-bold uppercase">
                Client Worldwide
              </p>
            </div>
            <div className="w-[275px] h-[170px] bg-gradient-to-r from-[#1F1F1F] to-[#141414] shadow-2xl rounded-xl p-4 flex flex-col items-center justify-center  shadow-black">
              <p className="text-4xl font-bold ">+50</p>
              <p className="text-[#616161] text-lg font-bold uppercase">
                Total Project
              </p>
            </div>
          </div>
        </div>

        <Link to="/contact" className=" relative flex justify-center ">
          <div className="group w-5/6 xl:w-full bg-gradient-to-r from-[#1F1F1F] to-[#141414] rounded-xl p-4 active:scale-90 duration-500 shadow-xl shadow-black">
            <img src="icon2.webp" alt="" />
            <div className="flex justify-between">
              <div>
                <p className="text-4xl xl:text-6xl font-bold">Let's </p>
                <p className="text-4xl xl:text-6xl font-bold">
                  Work <span className="text-[#00FFFF]">Together</span>
                </p>
              </div>
              <FaArrowRightLong className="text-[#989898] group-hover:text-white duration-300 absolute bottom-8 right-12 xl:right-20" />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default About;
