import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="bg-[#0F0F0F] mb-24 mt-2">
      <div className="flex justify-center xl:pt-12">
        <div className=" xl:flex gap-8">
          <Link
            to="/about"
            className=" flex-col xl:flex-row group relative p-8 shadow-xl w-5/6 h-auto mx-auto xl:w-[638px] h-[350px] flex justify-center items-center bg-gradient-to-r from-[#1F1F1F] to-[#141414] shadow-black gap-4 bg-clip-border rounded-3xl active:scale-90 duration-300 "
          >
            <img className="w-[160px] xl:w-[250px] rounded-full" src="uttam.jpg" alt="" />
            <div>
              <p className="text-md font-bold text-[#8B8B8B] text-center xl:text-left">
                Full Stack Developer(MERN)
              </p>
              <p className="text-3xl xl:text-4xl font-bold text-white uppercase mt-4">
                Uttam
              </p>
              <p className="text-3xl xl:text-4xl font-bold text-white uppercase mt-1">
                Kumar Saha
              </p>
              <p className="text-md text-[#8B8B8B] mt-2">
                A Web developer & programmer from Dhaka, Bangladesh
              </p>
            </div>
            <FaArrowRightLong className="text-[#989898] group-hover:text-white duration-300 absolute bottom-6 right-8" />
          </Link>
          <div>
            <div className="w-5/6 mx-auto mt-4 xl:mt-0  xl:w-[650px] h-[44px] bg-gradient-to-r from-[#1F1F1F] to-[#141414] rounded-2xl text-white flex items-center px-2 shadow-xl shadow-black">
              <marquee behavior="scroll">Contact me mail address: <b>mail@utamsaha.com</b> or call me to <b>+8801400014416</b></marquee>
            </div>
            <div className="flex flex-col xl:flex-row justify-center items-center gap-4 mt-6">
              <Link to="/skills" className="group">
                <div className=" relative w-[312px] h-[280px] bg-gradient-to-r from-[#1F1F1F] to-[#141414] p-4 bg-clip-border rounded-3xl active:scale-90 duration-300 shadow-xl shadow-black">
                  <div className="group">
                    <div className="flex justify-center">
                      <img className="h-[180px]" src="skill.webp" alt="" />
                    </div>
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="text-[#8B8B8B] uppercase font-bold text-sm mt-3">
                          More about me
                        </p>
                        <p className="text-2xl font-bold text-white">Skills</p>
                      </div>
                      <div className="">
                        <FaArrowRightLong className="text-[#989898] group-hover:text-white duration-300 absolute bottom-6 right-8" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
             
              <Link to="/projects" className="relative">
                <div className="group w-[312px] h-[280px] bg-gradient-to-r from-[#1F1F1F] to-[#141414] p-4 bg-clip-border rounded-3xl active:scale-90 duration-300 shadow-xl shadow-black">
                  <div>
                    <div className="flex justify-center">
                      <img className="h-[180px]" src="project.webp" alt="" />
                    </div>
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="text-[#8B8B8B] uppercase font-bold text-sm mt-3">
                          More about me
                        </p>
                        <p className="text-2xl font-bold text-white">Projects</p>
                      </div>
                      <div className="">
                        <FaArrowRightLong className="text-[#989898] group-hover:text-white duration-300 absolute bottom-6 right-8" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

     <div className="flex justify-center">
     <div className="flex flex-col xl:flex-row justify-center items-center mt-8 gap-4">
        <Link to="/blogs">
          <div className="group relative w-[312px] h-[280px] bg-gradient-to-r from-[#1F1F1F] to-[#141414] p-4 bg-clip-border rounded-3xl active:scale-90 duration-300 shadow-xl shadow-black">
            <div>
              <div className="flex justify-center">
                <img className="h-[180px]" src="blogs.webp" alt="" />
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-[#8B8B8B] uppercase font-bold text-sm mt-3">
                    All Blogs
                  </p>
                  <p className="text-2xl font-bold text-white">Blogs</p>
                </div>
                <div className="">
                  <FaArrowRightLong className="text-[#989898] group-hover:text-white duration-300 absolute bottom-6 right-8" />
                </div>
              </div>
            </div>
          </div>
        </Link>
        <Link to="/services">
          <div className="group w-[312px] h-[280px] xl:w-[645px] xl:h-[285px] bg-gradient-to-r from-[#1F1F1F] to-[#141414]  p-4 bg-clip-border rounded-3xl active:scale-90 duration-300 shadow-xl shadow-black">
            <div>
              <div className="mt-12 px-4 relative">
                <div className="flex justify-center items-center gap-8 xl:gap-20">
                  <img className="w-16 xl:w-20" src="design.webp" alt="" />
                  <img className="w-16 xl:w-20" src="wordpress.webp" alt="" />
                  <img className="w-16 xl:w-20" src="optimization.webp" alt="" />
                </div>
                <div className="flex justify-between items-center mt-14">
                  <div>
                    <p className="text-[#8B8B8B] uppercase font-bold text-sm mt-3">
                      More about me
                    </p>
                    <p className="text-2xl font-bold text-white">
                      Service Offering
                    </p>
                  </div>
                  <div className="">
                    <FaArrowRightLong className="text-[#989898] group-hover:text-white duration-300 absolute bottom-2 right-4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
        <Link to="/contact">
          <div className="group relative w-[312px] h-[280px] bg-gradient-to-r from-[#1F1F1F] to-[#141414]  p-4 bg-clip-border rounded-3xl active:scale-90 duration-300 shadow-xl shadow-black">
            <div>
              <div className="flex justify-center">
                <img className="h-[180px]" src="contact.webp" alt="" />
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-[#8B8B8B] uppercase font-bold text-sm mt-3">
                    stay connected
                  </p>
                  <p className="text-2xl font-bold text-white">Contact Me</p>
                </div>
                <div className="">
                  <FaArrowRightLong className="text-[#989898] group-hover:text-white duration-300 absolute bottom-6 right-8 " />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
     </div>
    </div>
  );
};

export default Home;
