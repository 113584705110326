import React from "react";
import { MdMarkEmailRead } from "react-icons/md";
import { PiPhoneCall } from "react-icons/pi";
import { ImLocation2 } from "react-icons/im";
import { Link } from "react-router-dom";


const Contact = () => {
  return (
    <div className="text-white mt-12 flex flex-col lg:flex-row justify-center gap-12 container mx-auto">
      <div className="">
        <p className="text-2xl text-center xl:text-left  xl:text-xl uppercase mb-6">Contact Info</p>
        <div className="flex gap-4 mb-6 ml-12 xl:ml-0">
          <div className="bg-gradient-to-r from-[#272727] to-[#302f2f] shadow-xl rounded-md w-16 h-16 flex items-center">
            <MdMarkEmailRead className="text-5xl mx-auto" />
          </div>
          <div>
            <p className="text-[#8B8B8B] uppercase font-bold">Email Me</p>
            <p>mail@uttamsaha.com</p>
            <p>uttamsdev@gmail.com</p>
          </div>
        </div>
        <div className="flex gap-4 mb-6 ml-12 xl:ml-0">
          <div className="bg-gradient-to-r from-[#272727] to-[#302f2f] shadow-xl rounded-md w-16 h-16 flex items-center">
            <PiPhoneCall className="text-5xl mx-auto" />
          </div>
          <div>
            <p className="text-[#8B8B8B] uppercase font-bold">Call Me</p>
            <p>+8801400014416</p>
            <p>+15654645555</p>
          </div>
        </div>
        <div className="flex gap-4 mb-6 ml-12 xl:ml-0">
          <div className="bg-gradient-to-r from-[#272727] to-[#302f2f] shadow-xl rounded-md w-16 h-16 flex items-center">
            <ImLocation2 className="text-5xl mx-auto" />
          </div>
          <div>
            <p className="text-[#8B8B8B] uppercase font-bold">Location</p>
            <p>Mohakhali, Amtoli</p>
            <p>Dhaka, Bangladesh</p>
          </div>
        </div>
        <div>
          <div className="">
            <p className="text-xl uppercase mb-3 mt-8 text-center xl:text-left ">Social Info</p>
            <div>
              <div className="flex gap-4 justify-center xl:justify-normal">
                <Link className="w-[40px]" to="https://www.linkedin.com/in/uttamsaha404/" target="_blank"><img className="w-12" src="s1.webp" alt="" /></Link>
                <Link className="w-[40px]" to="https://github.com/uttamsaha" target="_blank"><img className="w-12" src="s2.webp" alt="" /></Link>
                <Link className="w-[40px]" to="https://join.skype.com/invite/UlqUgBACVMqb" target="_blank"><img className="w-12" src="s3.webp" alt="" /></Link>
                <Link className="w-[40px]" to="https://facebook.com/uttamsaha404" target="_blank"><img className="w-12" src="s4.webp" alt="" /></Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-10/12 mx-auto xl:mx-0 xl:w-[866px] h-[580px] xl:h-[660px] bg-gradient-to-r from-[#1F1F1F] to-[#141414] rounded-lg shadow-xl shadow-black">
        <div className="flex justify-between items-center">
        <p className="text-5xl font-bold ml-8 pt-4 mb-8">
          Let's Code <span className="text-[#00FFFF]">Together</span>
        </p>
        <img src="icon2.webp" alt="" />
        </div>
        <div className="flex flex-col items-center text-white">
          <input className="bg-slate-200 w-10/12 xl:w-[770px] h-[50px] outline-none  rounded  mb-3 pl-3 bg-gradient-to-r from-[#272727] to-[#302f2f] shadow-6xl " type="text" placeholder="Your name" />
          <input className="bg-slate-200 w-10/12 xl:w-[770px] h-[50px] outline-none  rounded  mb-3 pl-3 bg-gradient-to-r from-[#272727] to-[#302f2f] shadow-xl " type="text" placeholder="Your email" />
          <input className=" bg-slate-200 w-10/12 xl:w-[770px] h-[50px] outline-none  rounded  mb-3 pl-3 bg-gradient-to-r from-[#272727] to-[#302f2f] shadow-xl " type="text" placeholder="Your subject" />
          <textarea className="bg-slate-200 w-10/12 xl:w-[770px] h-[150px] xl:h-[250px] outline-none  rounded  mb-3 pl-3 pt-2 bg-gradient-to-r from-[#272727] to-[#302f2f] shadow-xl " type="text" placeholder="Your message" />
          <button className=" text-sm mr-2 text-white active:scale-75 uppercase font-medium bg-gray-600 py-3 px-8 rounded-full hover:bg-white w-10/12 xl:w-[770px] mb-4 hover:text-black duration-500 mt-4">Send message</button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
