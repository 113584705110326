import React from "react";

const SingleSkill = ({ skill }) => {
  const { name, img, level, color } = skill;
  // console.log(color);
  return (
    <div className="cursor-pointer relative w-[300px] h-[226px] md:w-[240px] sm:w-[240px] lg:w-[300px] text-white bg-gradient-to-r from-[#1F1F1F] to-[#141414] flex items-center justify-center mb-8 rounded-2xl active:scale-90 duration-1000 shadow-xl shadow-black">
      <div className="flex absolute right-2 top-4 gap-2">
        <div className="w-3 h-3 rounded-full bg-red-500 hover:brightness-75"></div>
        <div className="w-3 h-3 rounded-full bg-yellow-500 hover:brightness-75"></div>
        <div className="w-3 h-3 rounded-full bg-green-500 hover:brightness-75"></div>
      </div>

      <div>
        <img className="w-20 mx-auto mb-2" src={img} alt="" />
        <p className="font-bold text-2xl lg:text-3xl">{name}</p>
        <button style={{backgroundColor: color}} className=" mx-auto w-full h-8 rounded-full mt-3 px-2 uppercase">
          {level}
        </button>
      </div>
    </div>
  );
};

export default SingleSkill;
