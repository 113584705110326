import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FaGithub } from "react-icons/fa";
import { CiYoutube } from "react-icons/ci";
import { MdRemoveRedEye } from "react-icons/md";



let searchProduct;

const ViewProject = () => {
  const [projects, setProjects] = useState([]);
  const { projectId } = useParams();
  console.log("projects: ", projects);

  useEffect(() => {
    fetch("/projects.json")
      .then((res) => res.json())
      .then((data) => setProjects(data));
  }, []);

  for (let project of projects) {
    console.log(project.id);
    console.log("projectId: ", projectId);
    if (project.id === parseInt(projectId)) {
      searchProduct = project;
    }
  }

  console.log("serch : ", searchProduct);
  return (
    <div className="text-white w-full xl:w-[1350px] mx-auto mt-8 pt-6 bg-gradient-to-r from-[#1F1F1F] to-[#141414] shadow-black gap-4 bg-clip-border rounded-3xl">
      <div>
        <div className="">
            <div className="flex flex-col xl:flex-row items-center xl:flex-wrap  gap-4 justify-center">
            {
                
                searchProduct?.img?.map(img => <div className="">
                <img className="w-[400px] h-[280px] xl:w-[650px] xl:h-[350px] rounded-xl mx-center active:scale-90 duration-300" src={img} alt="" />
                </div>)
            }
            </div>

            <div className="p-12 px-12">
                <p className="text-[#8B8B8B] uppercase font-bold text-xl">{searchProduct?.subtitle}</p>
                <p className="text-4xl font-white  font-bold mb-6">{searchProduct?.mainTitle}</p>
                <div>
                    <p className="text-2xl font-bold">Description:</p>
                    <p className="text-[#8B8B8B] text-lg">{searchProduct?.descriptions}</p>
                </div>
                <div>
                    <p className="text-2xl font-bold mt-4">Features: </p>
                    <p className="text-[#8B8B8B] text-lg"> {searchProduct?.features?.map((item, index) => <div className="mb-4 xl:mb-2">
                    <p>{index+1}. {item}</p>
                    </div>)}</p>
                </div>
            
                <div>
                    <p className="text-2xl font-bold mt-4">Technologies Used: </p><p> {searchProduct?.technologies?.map((item, index) => <div className="mb-2">
                    <p className="text-[#8B8B8B] text-lg">{index+1}. {item}</p>
                    </div>)}</p>
                </div>

                <div className="flex flex-col xl:flex-row items-center gap-4 mt-4 ">
                    <Link to={searchProduct?.sourceCode}  target="_blank"className='uppercase bg-[#424242] rounded-full py-3 px-6 text-sm hover: hover:bg-stone-200 font-bold hover:text-black active:scale-90 duration-500 flex gap-1'>Source Code <FaGithub className="text-lg"/></Link>
                    <Link to={searchProduct?.live}  target="_blank"className='uppercase bg-[#424242] rounded-full py-3 px-6 text-sm hover: hover:bg-stone-200 font-bold hover:text-black active:scale-90 duration-500 flex gap-1'>Live Website <MdRemoveRedEye className="text-lg"/></Link>
                    <Link to={searchProduct?.youtubeView} target="_blank" className='uppercase bg-[#424242] rounded-full py-3 px-6 text-sm hover: hover:bg-stone-200 font-bold hover:text-black active:scale-90 duration-500 flex gap-1'>View Project in youtube <CiYoutube className="text-xl"/></Link>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProject;
